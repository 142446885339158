.checkout {
  @extend %bloc-spacing;
  &__shipping-summary {
    margin-bottom: 40px;
  }
  &__ul {
    background-color: #F4F4F4;
    padding: 20px;
    width: max-content;
  }
  &__li {
    font-size: calculateRem(17);
    &--name, &--country {
      margin-bottom: 30px;
    }
  }
  &__label {
    display: flex;
    flex-flow: column;
    padding: 12px;
    border: 2px solid $sixth;
    border-radius: 4px;
    cursor: pointer;
    &:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 8px;
    }
    &--card {
      &:before {
        @include size(82px, 16px);
        background-size: 82px 16px;
        background-image: url("../../img/components/card-logo.svg");
      }
    }
    &--bancontact {
      &:before {
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 8px;
        @include size(26px, 16px);
        background-size: 26px 16px;
        background-image: url("../../img/components/bancontact.svg");
      }
    }
  }
  &__radio {
    display: none;
    &:checked ~ label {
      border-color: black;
      background-color: $fifth;
    }
  }
  &__choice-payment {
    display: flex;
  }
  &__container-choice-payment, &__container-card-element {
    margin-bottom: 20px;
  }
  &__container-card-element {
    border: 2px solid #E0E0E0;
    border-radius: 4px;
    padding: 8px;
    height: 36px;
  }
  &__container-checkboxes {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  &__bancontact-input {
    border: none;
    @include size(100%);
    padding: 0;
    appearance: none;
    display: block;
  }

  .error {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .error {
    color: #e4584c;
  }
}